import React, { useEffect, useState,useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { getRestaurantFood } from "../utils/api";
import Navbar from "../components/Navbar";
import FoodCategory from "../components/FoodCategory";
import Footer from "../components/Footer";
import FooterMobileMenu from "../components/FooterMobileMenu";
import Delivery from "../components/delivery";
import Map from "../components/Map";
import Cartcomponent from "../components/CartComponent"
import CartFloat from "../components/cartFloat";
import CategoriesAccordion  from "../components/CategoryinRow"
const Restaurant = () => {
  const perems = useParams();
  const [restraDetail, setRestraDetail] = useState([]);
  const [restdata, setRestdata] = useState();
  const [time,setTime] = useState([])
  const [foods, setFoods] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [payment,setPayment] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
  // const [restraDetail , setRestraDetail] = useState()
  const [isLoading, setIsLoading] = useState(true);
  
  const sendData = {
    // pincode: "4430008",
    restid: perems.id,
  };
  console.log(sendData,"dataa")
  const getDetail = async () => {

    const res = await getRestaurantFood(sendData);
    setFoods(res?.restaurantmenulist);
    setRestraDetail(res?.restaurantdetails);
    if(res?.takeawytime){
      setTime(res.takeawytime)
      console.log(res.takeawytime,"time")
    }

    if (res?.restdata) {
      setRestdata(res?.restdata[0]);
    }
    // console.log("res?.restaurantmenulist", restraDetail[0].lat);
    setPayment(res.paymentmethod);
    setIsLoading(false);
  };

  useEffect(() => {
    getDetail();
    console.log("restdata", restdata);
    // console.log("perems", perems.id);
  }, []);


// search items 

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredFoods = foods.filter((category) =>
    category.items.some((food) =>
      food.foodname.toLowerCase().includes(searchQuery)
    )
  );

// end 
  const [activeButton, setActiveButton] = useState(1);




useEffect(() => {
  const handleScroll = () => {
    const sections = document.querySelectorAll('.food-category-section');
    let currentCategory = null;

    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      if (rect.top < 10 && rect.bottom > 10) { // Adjust the value to fine-tune based on your layout
        currentCategory = section.id;
      }
    });

    console.log(currentCategory)
    if (currentCategory) {
      setActiveCategory(currentCategory);
    }
  };

  window.addEventListener("scroll", handleScroll);
  return () => window.removeEventListener("scroll", handleScroll);
}, [filteredFoods]);

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

const windowWidth = useWindowWidth();

// Handle category click
const handleCategoryClick = (category) => {
  setActiveCategory(category); // Update the active category on click

  const section = document.getElementById(category);
  if (section) {
    // Scroll to the clicked section smoothly
    window.scrollTo({
      top: section.offsetTop - 100, // Adjust the offset if you have a sticky navbar
      behavior: "smooth",
    });
  }
};
  

const categoryListRef = useRef(null);
const [isManualScroll, setIsManualScroll] = useState(false);

// Fallback for onCategoryClick if not provided
const handleCategorysClick = (category) => {
  setIsManualScroll(true);
  if (handleCategoryClick) {
    handleCategoryClick(category);
  } else {
    console.warn("onCategoryClick is not provided, active category won't be updated.");
  }
};

useEffect(() => {
  if (!isManualScroll && categoryListRef.current) {
    const activeIndex = filteredFoods.findIndex(
      (category) => category.category === activeCategory
    );

    if (activeIndex !== -1) {
      const activeItem = categoryListRef.current.children[activeIndex];
      const activeItemTop = activeItem.offsetTop; // Get the top offset
      const containerHeight = categoryListRef.current.clientHeight; // Get the height of the container
      const itemHeight = activeItem.clientHeight; // Get the height of the active item

      // Scroll to the active item vertically
      categoryListRef.current.scrollTo({
        top: activeItemTop - containerHeight / 2 + itemHeight / 2, // Adjust to center the active item
        behavior: 'smooth',
      });
    }
  }
}, [activeCategory, filteredFoods, isManualScroll]);

useEffect(() => {
  if (isManualScroll) {
    const timer = setTimeout(() => setIsManualScroll(false), 1000);
    return () => clearTimeout(timer);
  }
}, [isManualScroll]);


  return (
<>
  {/* Header section start */}
  <Navbar isStatic={true}/>
  {/* Header Section end */}
  <section className="section-t-space mytabb overflow-hidden "  style={{ paddingTop: '26px' }}>
    
    <Delivery/>

  </section>
  {/* banner section starts */}
  {isLoading ? ( // Show this while loading
        <div className="loading-spinner" style={{ textAlign: 'center', margin: '100px 0' }}>
          <h3>Loading...</h3> {/* You can replace this with a spinner or skeleton component */}
        </div>
      ) : (
  <div className="content" style={{paddingTop:"0px"}}>
  <div className="parent-div">
  <div className="child1-div" style={{width:"75%"}}>
  <section className="position-relative">
    <div>
      <img
      className="child1-div-img"
        src={`https://highwycombebites.com/documents/${restdata?.imagename}`}
      />
    </div>
    <div className="restaurant-image">
      <img
        className="img-fluid img "
        src={`https://highwycombebites.com/documents/${restdata?.logo}`}
        alt="brand13"
      />
    </div>
  </section>
  {/* banner section end */}
  <section className="pt-0">
    <div className="container">
      <div className="row justify-content-center">
        {/* <div className="restaurant-box"> */}
          <div className="restaurant-details">
            <div className="d-flex justify-content-between flex-wrap gap-3">
              <div>
                <h2 className="restaurant-name">
                {restdata?.name}
                </h2>
                {/* <p className="restaurant-place mt-2">
                  {restdata?.location}
                </p> */}
                <h6 className="food-items">
                {`${restdata?.cat1 ? restdata?.cat1 : "ㅤ"}${
                restdata?.cat2 ? " • " + restdata?.cat2 : ""
              }${restdata?.cat3 ? " • " + restdata?.cat3 : ""}`}
                </h6>
                   {restdata?.isonline == "0" && (
                                    <h6 className="food-items" style={{color:"red"}}>
                                      Currently Closed
                                    </h6>

                   )}
                <ul className="details-list" style={{ display: 'flex', flexDirection: 'column' }}>
  {restdata?.distance && (
    <li>
      <i className="ri-map-pin-fill theme-color" /> {restdata.distance}
    </li>
  )}
  {/* { restdata?.mincookduration && (
  <li>
    <i className="ri-time-line" /> {restdata?.mincookduration}
  </li>
  )
} */}
</ul>

              </div>
              <div className="restaurant-description">
                <div className="distance d-flex">
                  <h4 className="rating-star" style={{fontSize:"14px"}}>
                    <span>
                      <i className="ri-star-s-fill text-warning" />
                    </span>{" "}
                    {restdata?.totalreview.split(' ')[0]} <span style={{color:"rgb(116 116 116)"}}>({restdata?.totalreview.split(' ')[1]} Reviews)</span>
                    


                  </h4>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
 
   {/* </div> */}
  </section>
  {/* tab section starts */}
  <section className="tab-details-section section-b-space">
    <div className="container">
      <div className="blog-boxs">
        <div className="row justify-content-center g-4">
          <div className="col-lg-3 order-lg-0 order-1">
            <div className="left-box wow fadeInUp">
              <div className="shop-left-sidebar">
                <div className="search-box">
                  <div className="form-input position-relative">
                    <input
                     type="search"
                     className="form-control search"
                     id="search"
                     placeholder="Search"
                     value={searchQuery}
                     onChange={handleSearch}
                    />
                    <i className="ri-search-line search-icon" />
                  </div>
                </div>
                <div
                  className="accordion sidebar-accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul
                        ref={categoryListRef}
                          className="category-list custom-padding custom-height scroll-bar"
                          id="myDIV"
                        >
                      {filteredFoods.map((category,i)=>(
                                <li className="btnn " key={i}
                                        style={{ backgroundColor: activeCategory === category.category ? '#dff5ff' : '',
                                          border: activeCategory === category.category ? '1px solid #0dcaf0ab' : '',
                                          borderRadius: activeCategory === category.category ? '10px' : ''}}
                                          onClick={() => handleCategorysClick(category.category)}
                                          >
                                          {console.log(category.category,)}
                              
                                     <a href={`#${category.category}`}>
                                  <div className="form-check ps-0 m-0 category-list-box">
                                    <div className="form-check-label">
                                      <span className="name">{category.category}</span>
                                    </div>
                                  </div>
                                </a>
                              </li>

                      ))}

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div
              className="col-lg-9 p-0"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
              }}
            >
              <ul className="nav nav-tabs tab-style1" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeButton === 1 ? 'active' : ''}`}
                    id="order-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#online"
                    type="button"
                    role="tab"
                    onClick={() => setActiveButton(1)}
                  >
                    MENU
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeButton === 2 ? 'active' : ''}`}
                    id="overview-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#overview"
                    type="button"
                    role="tab"
                    onClick={() => setActiveButton(2)}
                  >
                    INFORMATION
                  </button>
                </li>
              </ul>
     
             

             

              <div
                className="tab-content product-details-content"
                id="myTabContent"
              >
                <div className={`tab-pane fade ${activeButton === 1 ? 'show active' : ''}`}
                  id="online"
                  role="tabpanel"
                  tabIndex={0}
                >
                  <div className="row g-lg-3 g-2">
                    <div className="col-lg-12">
                      <div className="product-box-section section-b-space">
                        <div
                          data-bs-spy="scroll"
                          data-bs-target="#navbar"
                          data-bs-smooth-scroll="true"
                          className="scrollspy-example-2"
                          tabIndex={0}
                        >
                          <div className="product-details-box-list">
                          {console.log(restdata,"idsss")}

                      {filteredFoods.map((category, i) => (
                           <FoodCategory key={i} item={category} restId={perems?.id} />
                                 ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className={`tab-pane fade ${activeButton === 2 ? 'show active' : ''}`}
                  id="overview"
                  role="tabpanel"
                  tabIndex={0}
                >
                  
                  <div className="overview-section">
                    {restraDetail.map((info,i)=>(

  <div className="row g-3" key={i}>
    <div className="col-md-12 col-12">
      <div className="overview-content mb-3">
        <h5 className="mb-2">
          <i className="fas fa-info-circle" />  About Description
        </h5>
        <p>
  <p   dangerouslySetInnerHTML={{
                             __html: info?.description,
                           }}></p>        </p>
      </div>
      <div className="overview-content mb-3">
        <h5 className="mb-2">
          <i className="fa fa-credit-card" /> Payment Methhod
        </h5>
{payment.map((method)=>(
              <p>
              <i className="fa fa-check theme-color" /> {method}{" "}
            </p>
))}
       
      </div>
      <div className="overview-content mb-3">
        <h5 className="mb-2">
          <i className="fas fa-exclamation-triangle" style={{ color: 'blue', fontSize: '24px' }}/> Allergen Info
        </h5>
        <ul className="alergy">
          <li>{info?.allergies}</li>
   
        </ul>
      </div>
    </div>
    <div className="col-lg-12">
      <h5>
        <i className="fa fa-star-o" /> Food Hygiene Rating
      </h5>
      <div className="d-flex p-2 border">
      <div className="me-3 hyzine">
  { (Number(info.hyginerating) === 4 || Number(info.hyginerating) === 4.5) && (
    <img
      src="https://api.asm.skype.com/v1/objects/0-jhb-d1-e7a5f4f212304f4cef08e9f60526b166/views/imgpsh_fullsize_anim"
      style={{ width: 150 }}
    />
  )}
  { (Number(info.hyginerating) === 3 || Number(info.hyginerating) === 3.5) && (
    <img
      src="https://api.asm.skype.com/v1/objects/0-jhb-d10-89c65c18d37ffb29a29f6de44ccb1c6f/views/imgpsh_fullsize_anim"
      style={{ width: 150 }}
    />
  )}
  { (Number(info.hyginerating) === 2 || Number(info.hyginerating) === 2.5) && (
    <img
      src="https://api.asm.skype.com/v1/objects/0-jhb-d3-5a606b885a8c8ebf468638397b97e265/views/imgpsh_fullsize_anim"
      style={{ width: 150 }}
    />
  )}
  { (Number(info.hyginerating) === 1 || Number(info.hyginerating) === 1.5 || info.hyginerating === "") && (
    <img
      src="https://api.asm.skype.com/v1/objects/0-jhb-d7-bccffa8e6defef87cafb1eb99a8479f7/views/imgpsh_fullsize_anim"
      style={{ width: 150 }}
    />
  )}
  { (Number(info.hyginerating) == 5) && (
    <img
      src="https://api.asm.skype.com/v1/objects/0-jhb-d7-bccffa8e6defef87cafb1eb99a8479f7/views/imgpsh_fullsize_anim"
      style={{ width: 150 }}
    />
  )}
</div>

        <div>
          <h6>Last Inspection 27 March 2024</h6>
          <p>
            Rating b the Food Standard Agency and our local authority. This
            rating may have changed
          </p>
          <a href="https://ratings.food.gov.uk/" target="_blank">
            More Info
          </a>
        </div>
      </div>
    </div>
    <div className="col-md-12 col-12">
      <h5>
        <i className="fa fa-map" /> Where to Find Us
      </h5>
      <div className="overview-content mt-0 pl-3">
   <Map/>
      </div>
    </div>
    <div className="col-md-12 col-12">
      <h5>
        <span className="me-2">Contact Us:</span>{" "}
        <a href="tel:+919999999999" data-rel="external">
          {" "}
          <i className="fa fa-phone" /> {info.contactno}
        </a>
      </h5>
    </div>
  </div>
                      ))}

</div>



                </div>


              </div>
            </div>
     
        </div>
      </div>
    </div>
  </section>


  
  </div>
  <div className="child2-div"  style={{position: 'relative', width:"25%"}}>

    <Cartcomponent/>
  </div>
  </div>
  <CategoriesAccordion 
        filteredFoods={filteredFoods} 
        activeCategory={activeCategory}
        onCategoryClick={handleCategorysClick}
      />
  <div style={{ display: windowWidth <= 576 ? "block" : "none" }}>
        <CartFloat />
      </div>

  </div>
      )}
  {/* tab section end */}
  <Footer/>
  {/* footer section end */}
  {/* mobile fix menu start */}
  <FooterMobileMenu/>
  {/* mobile fix menu end */}
  {/* cart fix panel */}
  {/* <div className="fixed-btn d-lg-none d-block">
    <div className="custom-container">
      <div className="cart-fixed-bottom">
        <h6 className="fw-medium">3 items</h6>
        <Link to={"/cart"} className="cart-fixed-right">
          <h6 className="fw-medium text-white">
            View cart <i className="ri-arrow-right-line" />
          </h6>
        </Link>
      </div>
    </div>
  </div> */}
  {/* cart fix panel */}
  {/* customized modal starts */}
  {/* <div className="modal customized-modal" id="customized" tabIndex={-1}>
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="container">
          <div className="filter-header">
            <h5 className="title">Custom Food</h5>
            <a
              href="#"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="filter-body">
            <div className="filter-title">
              <h5 className="fw-medium dark-text">Choose Size</h5>
            </div>
            <ul className="filter-list">
              <li>
                <h6 className="product-size">Size S</h6>
                <div className="form-check product-price">
                  <label className="form-check-label" htmlFor="reverseCheck1">
                    $18
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="reverseCheck1"
                  />
                </div>
              </li>
              <li>
                <h6 className="product-size">Size M</h6>
                <div className="form-check product-price">
                  <label className="form-check-label" htmlFor="reverseCheck1">
                    $20
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="reverseCheck2"
                    defaultChecked=""
                  />
                </div>
              </li>
              <li>
                <h6 className="product-size">Size L</h6>
                <div className="form-check product-price">
                  <label className="form-check-label" htmlFor="reverseCheck1">
                    $22
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="reverseCheck3"
                  />
                </div>
              </li>
            </ul>
            <div className="filter-title">
              <h5 className="fw-medium dark-text">Choose Size</h5>
            </div>
            <ul className="filter-list border-0">
              <li>
                <h6 className="product-size">Chili sauce</h6>
                <div className="form-check product-price">
                  <label className="form-check-label" htmlFor="reverseCheck1">
                    $18
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="reverseCheck4"
                  />
                </div>
              </li>
              <li>
                <h6 className="product-size">Mustard</h6>
                <div className="form-check product-price">
                  <label className="form-check-label" htmlFor="reverseCheck1">
                    $20
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="reverseCheck5"
                    defaultChecked=""
                  />
                </div>
              </li>
              <li>
                <h6 className="product-size">Special dipping sauce</h6>
                <div className="form-check product-price">
                  <label className="form-check-label" htmlFor="reverseCheck1">
                    $22
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="reverseCheck6"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="filter-footer">
            <a
              href="#"
              className="btn theme-btn add-btn w-100 mt-0"
              data-bs-dismiss="modal"
            >
              Apply
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* customized modal end */}
  {/* location offcanvas start */}
  {/* <div
    className="modal fade location-modal"
    id="location"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            <h5 className="fw-semibold">Select a Location</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="search-section">
            <form className="form_search" role="form">
              <input
                type="search"
                placeholder="Search Location"
                className="nav-search nav-search-field"
              />
            </form>
          </div>
          <a href="" className="current-location">
            <div className="current-address">
              <i className="ri-focus-3-line focus" />
              <div>
                <h5>Use current-location</h5>
                <h6>Wellington St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
            <i className="ri-arrow-right-s-line arrow" />
          </a>
          <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
            Recent Location
          </h5>
          <a href="" className="recent-location">
            <div className="recant-address">
              <i className="ri-map-pin-line theme-color" />
              <div>
                <h5>Bayshore</h5>
                <h6>kingston St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="modal-footer">
          <a href="#" className="btn gray-btn" data-bs-dismiss="modal">
            Close
          </a>
          <a href="#" className="btn theme-btn mt-0" data-bs-dismiss="modal">
            Save
          </a>
        </div>
      </div>
    </div>
  </div> */}
  {/* location offcanvas end */}
  {/* tap to top start */}
  <button className="scroll scroll-to-top menu-page">
    <i className="ri-arrow-up-s-line arrow" />
  </button>
  {/* tap to top end */}
</>

  )
}

export default Restaurant






