import React, { useEffect, useState } from "react";
import { addToCart } from "../utils/api";
import { getcart,incrementCount } from "../store/feature/cartSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AddonPopup from "./AddonPopup";
// import AddonPopup from "./AddonPopup";

const Food = ({ onClose, food, restId }) => {
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const {count } = useSelector((store) => store.Cart);
  const activeTab = useSelector((store)=> store.User.activeTab)
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // Only dispatch getcart if cartCount is not null
  //   if (count  !== null) {

  //     dispatch(getcart());
  //   }
  // }, [count, dispatch]);


const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false);
  const toggle = () => {
    setShowPopup(!showPopup);
  };


  const handleIncrement = () => {
    dispatch(incrementCount());
};
  const handleClick = () => {
    handleIncrement()
    toggle()
};
  return (
    <>
      <div id="item-3">
        <div className="product-details-box">
          <div className="product-img">
          {food?.image != "PhotoNotAvailable.png" && food?.image !=  "PhotoNotSelected.png" ? (
            <img
              className="img-fluid img"
              src={`https://highwycombebites.com/documents/${food?.image}`}
              alt="rp1"
            />
          ):<img/>}

          </div>
          <div className= {food?.image != "PhotoNotAvailable.png" && food?.image !=  "PhotoNotSelected.png" ?`product-content` : `product-content w-100`}>
            <div className="description d-flex align-items-center justify-content-between gap-1">
              <div>
                <div className="d-flex align-items-center gap-2">
                  {/* {food?.nonveg === "1" ? (
                    <img
                      className="img"
                      src="https://themes.pixelstrap.com/zomo-app/assets/images/svg/nonveg.svg"
                      alt="non-veg"
                    />
                  ) : (
                    <img
                      className="img"
                      src="https://themes.pixelstrap.com/zomo-app/assets/images/svg/veg.svg"
                      alt="veg"
                    />
                  )} */}
                  <h6 className="product-name">{food?.foodname}</h6>
                </div>



                {/* <div className="rating-section">
                  <ul className="rating-star">
                    <li>
                      <i className="ri-star-fill star" />
                    </li>
                    <li>
                      <i className="ri-star-fill star" />
                    </li>
                    <li>
                      <i className="ri-star-fill star" />
                    </li>
                    <li>
                      <i className="ri-star-fill star" />
                    </li>
                    <li>
                      <i className="ri-star-fill star" />
                    </li>
                  </ul>
                  <h6 className="rating-amount">1k+ Ratings</h6>
                </div> */}
                <p className="custom-description" 
                  dangerouslySetInnerHTML={{
                    __html: food?.description,
                  }}
                ></p>
              </div>
              <div className="product-box-price">
                {/* <h2 className="fw-semibold">£{(Number(food?.cost) || 0).toFixed(2)}</h2> */}
                <div onClick={handleClick}>
  <button
    className="btn theme-outline add-btn mt-0"
    style={{ fontSize: '18px', fontWeight: 'bold', padding: '2px 10px' }} // Adjusted size and padding
  >
    +
  </button>
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* customized modal starts */}
      {showPopup && <AddonPopup onClose={toggle} food={food} restId={restId} />}
    {/* <div className="modal customized-modal" id="customized" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered">
      <AddonPopup onClose={toggle} food={food} restId={restId} />
      </div>
    </div> */}
    {/* customized modal end */}
    </>
  );
};

export default Food;



//  redirect on this page https://highwycombebites.netlify.app/bookingconfirm after To Book dining Booking 
//  redirect on this page https://highwycombebites.netlify.app/confirmorder after To Place Order Takeaway/diliverty
// this is url of websites https://highwycombebites.netlify.app