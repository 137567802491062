import React, { useEffect, useState, useCallback } from "react";

import { SwiperSlide } from "swiper/react";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCategorylist, getDining, getOfferRest, getRestaurant, getTopRest } from "../store/feature/restaurantSlice";
import RestaurantCard from "../components/RestaurantCard";
import Navbar from "../components/Navbar";
import RestaurantCategory from "../components/RestaurantCategory";

import CategoryFoods from "../components/CategoryFoods";
import Footer from "../components/Footer";
import FooterMobileMenu from "../components/FooterMobileMenu";
import { FabComponent } from '@syncfusion/ej2-react-buttons';
import { search } from "../utils/api";
import Delivery from "../components/delivery";
import CartFloat from "../components/cartFloat";
import CookieConsent from "../components/Cookie";

const Home = () => {

  const [idss, setIdss] = useState("")
  const storedPincode = JSON.parse(localStorage.getItem("pincode")) || "";
  const pincode = storedPincode ? storedPincode.longName : "";
  const user = JSON.parse(localStorage.getItem("userAddress"))
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };
  console.log(user, "usersss")

  const [input, setInput] = useState("");
  const [restaurants, setRestaurants] = useState([]);

  // Function to fetch restaurants
  const fetchRestaurants = useCallback(async () => {
    if (input.trim() === "") {
      setRestaurants([]); // Clear results if input is empty
      return;
    }

    try {
      const data = {
        name: input,
        pincode: pincode.toString(),
        lat: user?.lat || "	51.5072",
        lng: user?.lng || "-0.1275",
        isdining: '1'
      };
      console.log(data, "searchess")
      const response = await search(data); // Replace `search` with your actual API call

      if (response.status === '1') {
        setRestaurants(response.searchrestauarant);
      } else {
        setRestaurants([]);
      }
    } catch (err) {
      console.error(err);
      setRestaurants([]);
    }
  }, [input]);


  useEffect(() => {
    new Swiper(".swiper-rest-1", {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: ".next-rest-1",
        prevEl: ".prev-rest-1",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: 1.5,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      },
    });
  }, []);

  useEffect(() => {
    new Swiper(".swiper-rest-3", {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: ".next-rest-3",
        prevEl: ".prev-rest-3",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: 1.5,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      },
    });
  }, []);


  const debouncedFetchRestaurants = useCallback(debounce(fetchRestaurants, 300), [fetchRestaurants]);

  // Effect to call debounced fetch function
  useEffect(() => {
    debouncedFetchRestaurants();
  }, [input, debouncedFetchRestaurants]);


  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion.name); // Set the input field value
    setIdss(suggestion.pkid)
    setRestaurants([]); // Clear suggestions after selection
    handleSearch(suggestion.pkid);
  };


  const handleNavigation = (text) => {
    if (text === 'All Restaurants') {
      navigate('/dining');
    } else {
      navigate('/Localheros');
    }
  };



  /////////// CSS ENDS //////////////////////////////////
  const navigate = useNavigate();
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const storedAddress = JSON.parse(localStorage.getItem("userAddress")) || "";
  // useEffect(() => {
  //   if (!storedUser) {
  //     navigate("/login");
  //   }
  // }, []);

  const [allRestaura, setAllRestaura] = useState([]);
  const dispatch = useDispatch();
  const { restaurant, diningRestaurant, topRestaurant, offeredRestaurant, categorylist } = useSelector((store) => store.restaurant);
  const { userAddress } = useSelector((store) => store.User);
  const storedPincodes = JSON.parse(localStorage.getItem("pincode")) || "";
  const pincodes = storedPincodes ? storedPincodes.longName : "HP12";

  useEffect(() => {
    // if (storedAddress ) {
    const dataforHome = {
      page: "1",
      lat: storedAddress?.lat || "51.5072",
      lng: storedAddress?.lng || "-0.1275",

      istakeway: "0",
      pincode: pincodes.toString(),
      sort: "0",
      userid: storedUser?.userid || "20",
      // pincode: "4430008",
    };
    console.log("storedAddress", storedAddress);
    dispatch(getRestaurant(dataforHome));
    const data = {
      page: "1",
      pincode: pincodes.toString(),
      lat: storedAddress?.lat || "51.5072",
      lng: storedAddress?.lng || "-0.1275",
      userid: storedUser?.userid || "20",
    };
    console.log()
    dispatch(getDining(data));
    dispatch(getTopRest(data));
    dispatch(getOfferRest(data));
    const catData = {
      sortingtype: "1"
    }
    dispatch(getCategorylist(catData));
    // }

  }, [userAddress]);

  useEffect(() => {
    // console.log("restaurant", restaurant);
    // console.log("diningRestaurant", diningRestaurant);
    // console.log("topRestaurant", topRestaurant);
    // console.log("offeredRestaurant", offeredRestaurant);
  }, [restaurant, diningRestaurant]);

  const handleSearch = (id) => {
    console.log("idsss for search", id)
    if (id) {
      navigate(`/restaurant/${id}`)
    }
  }
  return (
    <div>
      <>
        {/* Header section start */}
        <Navbar />
        {/* Header Section end */}
        <section className="section-t-space mytabb overflow-hidden pt-120">
          {/* <div className="container text-center">
            <div className="tab">
              <div>
                <Link className="tablinks active">
                  <p>
                    <i className="fa fa-motorcycle" /> Delivery
                  </p>{" "}
                  <p className="smtext">35 - 50 Min</p>
                </Link>
                <Link className="tablinks">
                  <p>
                    <i className="fa fa-shopping-bag" aria-hidden="true" /> Collection{" "}
                  </p>{" "}
                  <p className="smtext">15 - 25 Min</p>
                </Link>
              </div>
            </div>
          </div> */}
          <Delivery />
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-7 col-12">
                <h2 style={{ paddingTop: "16px" }}>Discover restaurants that food deliver near you</h2>
                <div className="search-section display-not">
                  <form className="auth-form search-head" target="_blank">
                    <div className="form-group">
                      <div className="form-input mb-0">
                        <input
                          type="search"
                          className="form-control search"
                          id="inputusername"
                          placeholder="Search for Restaurant"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                        />
                        {input && restaurants.length > 0 && (
                          <div className="suggestions list-unstyled result-bucket shadow-sm bg-white rounded">
                            <ul className="list-group">
                              {restaurants.map((restaurant, index) => (
                                <li
                                  key={index}
                                  className="list-group-item list-group-item-action"
                                  onClick={() => {
                                    handleSuggestionClick(restaurant);
                                    handleSearch(restaurant.id)
                                  }}
                                >
                                  {restaurant.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {restaurants.length == 0 && (

                          <i className="ri-search-line search-icon" />
                        )}
                      </div>
                    </div>
                  </form>
                  <button
                    className="btn theme-btn mt-0"
                    role="button"
                    onClick={() => handleSearch(idss)}

                  >
                    Search
                  </button>
                </div>
              </div>
              <div style={{ paddingTop: "10px" }} className="col-lg-5 position-relative">
                <img
                  src="assets/images/home.webp"
                  className="img-fluid right-vector"
                  alt="right-vector"
                />
              </div>
            </div>
          </div>
        </section>
        {/* home section start */}
        <section
          id="home"
          className="home-wrapper home2 section-b-space overflow-hidden"
        >
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-7 col-12">
                <h2 style={{ paddingTop: "16px" }}>Local Food Delivery App That Saves You Money and Supports the Local Community.</h2>
                <div className="search-section display-not">
                  <form className="auth-form search-head" target="_blank">
                    <div className="form-group">
                      <div className="form-input mb-0">
                        <input
                          type="search"
                          className="form-control search"
                          id="inputusername"
                          placeholder="Search for Restaurant"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                        />
                        {input && restaurants.length > 0 && (
                          <div className="suggestions list-unstyled result-bucket shadow-sm bg-white rounded">
                            <ul className="list-group">
                              {restaurants.map((restaurant, index) => (
                                <li
                                  key={index}
                                  className="list-group-item list-group-item-action"
                                  onClick={() => {
                                    handleSuggestionClick(restaurant);
                                  }}
                                >
                                  {restaurant.name} {/* Adjust based on your data structure */}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {restaurants.length == 0 && (

                          <i className="ri-search-line search-icon" />
                        )}
                      </div>
                    </div>
                  </form>
                  <button
                    className="btn theme-btn mt-0"
                    role="button"
                    onClick={() => handleSearch(idss)}

                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-lg-5 position-relative">
                <img
                  src="assets/images/home.webp"
                  className="img-fluid right-vector"
                  alt="right-vector"
                />
              </div>
            </div>
          </div>
        </section>
        {/* home section end */}
        {/* categories section starts */}
        <CategoryFoods data={categorylist} text={"What on Your Mind?"} />
        {/* categories section end */}
        {/* Featured Restaurants section starts */}
        <section className="restaurant-list  ratio3_2" style={{ display: restaurant && restaurant.length > 0 ? 'block' : 'none' }}>
          <div className="container">
            <div className="popular-restaurant section-md-t-space ratio3_2">
              <div className="title title-sm mt-0">
                <h2>Most favourited places</h2>
                <div className="loader-line" />
              </div>
              <div className="theme-arrow">
                <div className="swiper swiper-rest-1 popular-slider">
                  <div className="swiper-wrapper">

                    {
                      restaurant && restaurant.length > 0 ? (
                        restaurant.map((item) => (
                          <RestaurantCard key={item.pkid} item={item} getFavorite={getRestaurant} />
                        ))
                      ) : null
                    }
                  </div>
                </div>
                <div className="swiper-button-next popular-next next-rest-1" />
                <div className="swiper-button-prev popular-prev prev-rest-1" />
              </div>

            </div>
            <>
              <div style={{ textAlign: "right", marginTop: "5px" }}>
                <button className="btn theme-btn" onClick={handleNavigation}>
                  {" "}
                  View All{" "}
                </button>
              </div>
            </>

          </div>
        </section>
        {/* featured Restaurants section end */}
        {
          diningRestaurant?.restdata?.length > 0 ?
            <RestaurantCategory data={diningRestaurant?.restdata} text={'All Restaurants'} num={"2"} />
            : ""
        }

        {
          offeredRestaurant?.offerrest?.length > 0 ?
            <RestaurantCategory data={offeredRestaurant?.offerrest} text={'Family Meal Deals'} num={"7"} />
            : ""
        }

        {/* app section starts */}
        <section className="app-section section-b-space">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="app-img">
                <img
                  className="img-fluid phone"
                  src="/assets/images/service-phone.png"
                  alt="app-phone"
                />
              </div>
              <div className="app-content">
                <h2>Be Part of a Bigger Mission—Make a Positive Impact in High Wycombe.</h2>
                <h5>
                  Download the High Wycombe Bites app to support local restaurants with every order. Save money, unlock exclusive deals, and make a real difference in your community—all from one app!
                </h5>
                <div className="app-buttons d-flex align-items-center gap-3">
                  <a
                    href="https://apps.apple.com/gb/app/high-wycombe-bites/id6504797592"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid app-btn"
                      src="assets/images/svg/app-store.svg"
                      alt="app-store"
                    />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.app.wycombe_bytes&pcampaignid=web_share&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid app-btn"
                      src="assets/images/svg/google-play.svg"
                      alt="google-play"
                    />
                  </a>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/* app section end */}
        {/* footer section starts */}
        <Footer data={categorylist} />
        {/* footer section end */}
        {/* mobile fix menu start */}
        <FooterMobileMenu selected={"home"} />
        {/* mobile fix menu end */}
        {/* location offcanvas start */}

        {/* location offcanvas end */}
        {/* tap to top start */}
        <button className="scroll scroll-to-top">
          <i className="ri-arrow-up-s-line arrow" />
        </button>
        {/* tap to top end */}
        <CookieConsent />
        <CartFloat />
      </>
    </div>
  );
};

export default Home;


