
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import charity from '../assets/images/charity.png';
import Nonveg from '../assets/nonVeg.jpg';
import Veg from '../assets/Veg.jpg';
import { getCategorylist } from '../store/feature/restaurantSlice'
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

const Footer = () => {
  const [showMore, setShowMore] = useState(false);
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState({
    legal: false,
    cuisine: false,
    partners: false,
    customerService: false,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSection = (section) => {
    setIsOpen((prev) => {
      const newState = {
        legal: false,
        cuisine: false,
        partners: false,
        customerService: false,
      };
      newState[section] = true;
      return newState;
    });
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const shortText = `High Wycombe Bites is your local food delivery app, dedicated to supporting restaurants and takeaways in High Wycombe.`;
  const fullText = `High Wycombe Bites is your local food delivery app, 
                    dedicated to supporting restaurants and takeaways in High Wycombe.
                    We bring you exclusive offers and discounts that you won’t find anywhere else.
                    Our mission is to strengthen the local community by supporting local businesses 
                    and giving back through charitable donations. With every order, 
                    you’re not only satisfying your cravings but also making a positive impact in High Wycombe.`;

  const { categorylist } = useSelector((store) => store.restaurant);
  useEffect(() => {
    const catData = {
      sortingtype: "1"
    }
    dispatch(getCategorylist(catData));
  }, [])
  const [showAll, setShowAll] = useState(false);

  const toggleShowMores = () => {
    setShowAll(!showMore);
  };
  const categories = categorylist?.categorylist || [];

  return (
    <footer className="footer-section section-t-space">
      <div className="subscribe-section border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="subscribe-part">
                <h5>
                  Save on Every Bite! Be the first to receive exclusive insider deals, delivered straight to you.
                </h5>
                <div className="position-relative w-100">
                  <input
                    type="email"
                    className="form-control subscribe-form-control"
                    placeholder="Enter your Email"
                  />
                  <Link href="#" className="btn theme-btn subscribe-btn mt-0">
                    Subscribe Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="main-footer border-bottom">
          <div className="row g-3">
            <div className="col-xl-4 col-lg-12">
              <div className="footer-logo-part">
                <h5 className="footer-title">About Us</h5>
                <p>
                  {showMore ? fullText : shortText}
                  <span
                    style={{ color: '#009FE3', cursor: 'pointer' }}
                    onClick={toggleShowMore}
                  >
                    {showMore ? 'Show Less' : ' Show More'}
                  </span>
                </p>
                {/* <div className="diet-symbols d-flex align-items-center my-3">
                  <img
                    src={Veg}
                    alt="Vegetarian"
                    style={{ width: '30px', marginRight: '10px' }}
                  />
                  <img
                    src={Nonveg}
                    alt="Non-Vegetarian"
                    style={{ width: '30px', marginLeft: '10px' }}
                  />
                </div> */}
                <div className="social-media-part">
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61564768471774&locale=en_GB" target="_blank">
                        <i className="ri-facebook-fill icon" />
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/i/flow/login?redirect_after_login=%2Fwycombebites" target="_blank">
                        <i class="ri-twitter-x-fill icon" style={{ fontSize: "15px" }}></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.tiktok.com/@highwycombebites" target="_blank">
                        <i class="ri-tiktok-fill icon"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/highwycombebites?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
                        <i className="ri-instagram-fill icon" />
                      </a>
                    </li>
                    {/* Changed as per the client need */}
                    {/* <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="ri-youtube-fill icon" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row g-3">

                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <h5 className="footer-title" onClick={() => toggleSection('legal')} style={{ cursor: 'pointer' }}>Legal</h5>
                  <ul className="content" style={{
                    display: (isOpen.legal || windowWidth > 576) ? 'block' : 'none',
                  }}>
                    <li>
                      <Link to={"/term"}>
                        <h6>Terms of service</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy"}>
                        <h6>Privacy policy</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/cookie"}>
                        <h6>Cookies policy</h6>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <h5 className="footer-title" onClick={() => toggleSection('cuisine')} style={{ cursor: 'pointer' }}>Popular Cuisine</h5>


                  <ul className="content" style={{
                    display: (isOpen.cuisine || windowWidth > 576) ? 'block' : 'none',
                  }}


                  >
                    {categories
                      .slice(0, showAll ? categories.length : 7)  // Show full list or first 7 based on showAll state
                      .map((item) => (
                        <li key={item?.pkid}>
                          <Link to={`/dish/${item?.pkid}?name=${encodeURIComponent(item?.name)}`}>
                            <h6>{item?.name}</h6>
                          </Link>
                        </li>
                      ))}
                    {categories.length > 7 && (
                      <span
                        style={{ color: '#009FE3', cursor: 'pointer' }}
                        onClick={toggleShowMores}
                      >
                        {showAll ? '' : ' Show More'}
                      </span>
                    )}

                    {/* <li>
                      <Link>
                        <h6>Italian</h6>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <h6>Vegetarian</h6>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <h6>Indian</h6>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <h6>Pizza</h6>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <h6>American</h6>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <h6>Dessert</h6>
                      </Link>
                    </li> */}
                  </ul>



                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <h5 className="footer-title" onClick={() => toggleSection('partners')} style={{ cursor: 'pointer' }}>Partners</h5>
                  <ul className="content" style={{
                    display: (isOpen.partners || windowWidth > 576) ? 'block' : 'none',
                  }}>
                    <li>
                      <Link to={"/AddRestaurant"}>
                        <h6>Add your restaurant</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/ride"}>
                        <h6>Ride with us</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Bites"}>
                        <h6>Bites for business</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={""}>
                        <h6>Supported by Bites</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={""}>
                        <h6>Careers</h6>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <h5 className="footer-title" onClick={() => toggleSection('customerService')} style={{ cursor: 'pointer' }}>Customer Service</h5>
                  <ul className="content" style={{
                    display: (isOpen.customerService || windowWidth > 576) ? 'block' : 'none',
                  }}>
                    <li>
                      <Link to={"/login"}>
                        <h6>Log in</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/login"}>
                        <h6>Sign up</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/setting"}>
                        <h6>Setting</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Blog"}>
                        <h6>Blog</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/generateTicket"}>
                        <h6>Contact us</h6>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/faq"}>
                        <h6>FAQs</h6>
                      </Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className={`bottom-footer-part ${isMobile ? 'mb-5' : ''}`}>
          <div className="d-flex align-items-center justify-content-center flex-wrap gap-2">
            <div
              className="charity-section d-flex align-items-center"
              style={{
                textAlign: 'center',
                marginBottom: '20px',
                gap: '10px',
                paddingTop: '18px',
              }}
            >
              <div className="d-flex gap-3" style={{ textAlign: 'center' }}>
                <div>
                  <h5>We support</h5>
                  <div
                    style={{
                      marginTop: '5px', // Add margin between "We support" and the text
                      color: 'black',
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    5p of each order goes to
                  </div>
                </div>
                <a href="https://onecantrust.org.uk">
                  <img
                    src={charity}
                    alt="Charity Logo"
                    style={{ width: '130px', height: 'auto', marginTop: '8px' }}
                  />
                </a>
              </div>
            </div>
          </div>
          <h6 style={{ fontSize: "14px" }}>@ Copyright 2024 Food Delivery. All rights Reserved.</h6>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

