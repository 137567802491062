import React,{ useEffect, useRef,useState } from 'react'
import { BsPiggyBank } from "react-icons/bs";
import { MdOutlineSchedule } from "react-icons/md";
import { AiOutlineInsurance } from "react-icons/ai";
import Footer from '../components/Footer'
import FooterMobileMenu from '../components/FooterMobileMenu';
function RideWithUs() {

  const galleryRef = useRef(null);
  const galleryRef1 = useRef(null);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (galleryRef1.current) {
        // Scroll to the left by 160 pixels
        galleryRef1.current.scrollBy({ left: -160, behavior: 'smooth' });

        // Check if we've reached the leftmost end
        if (galleryRef1.current.scrollLeft <= 0) {
          // Reset to the rightmost position without animation for a continuous effect
          const maxScrollRight = galleryRef1.current.scrollWidth - galleryRef1.current.clientWidth;
          galleryRef1.current.scrollTo({ left: maxScrollRight, behavior: 'auto' });
        }
      }
    }, 3000); // Adjust timing as needed

    // return () => clearInterval(scrollInterval); // Clear the interval on component unmount
  }, []);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (galleryRef.current) {
        const maxScrollLeft = galleryRef.current.scrollWidth - galleryRef.current.clientWidth;
        if (galleryRef.current.scrollLeft >= maxScrollLeft) {
          galleryRef.current.scrollTo({ left: 0, behavior: "smooth" });
        } else {
          galleryRef.current.scrollBy({ left: 160, behavior: "smooth" });
        }
      }
    }, 3000); // Adjust timing as needed

    return () => clearInterval(scrollInterval);
  }, []);
  const [showMore, setShowMore] = useState(false);


  const shortText = `We collect this data for the purposes`;
  const fullText = `We collect this data for the purposes of processing
                    your application to become a courier. By clicking this box,
                    you acknowledge that you have read and understood the Privacy
                    Policy.`;

 const toggleShowMore = () => {
   setShowMore(!showMore);
 };                  
  return (
    <div>
<>
  {/* Header section start */}
  <header>
    <div className="container">
      <nav className="navbar navbar-expand-lg p-0">
        <a href="index.html">
          <img
            className="img-fluid logo"
            src="/assets/images/newlogo3.png"
            alt="logo"
          />
        </a>
        <button
          className="navbar-toggler ml-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#offcanvasNavbar"
        >
          <span className="navbar-toggler-icon">
            <i className="ri-menu-line" />
          </span>
        </button>
        <div className="nav-option order-md-2">
          <div className="nav-option order-md-2">
            <a href="javascript:void()" className="btn theme-btn">
              Apply
            </a>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasNavbar"
        >
          <div className="offcanvas-header">
            <h4
              className="offcanvas-title"
              id="offcanvasNavbarLabel"
              style={{ fontWeight: 600 }}
            >
              Menu
            </h4>
            {/*<button class="navbar-toggler btn-close" id="offcanvas-close"></button>*/}
            <button
              className="navbar-toggler btn-close"
              data-bs-toggle="collapse"
              data-bs-target="#offcanvasNavbar"
            />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-center flex-grow-1">
              <li className="nav-item">
                <a className="nav-link" href="ridewithus.html">
                  Ride with us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Add Restaurant
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Bites for business{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  {" "}
                  Supported by Bites
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Careers
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
  {/* Header Section end */}
  {/* home section start */}
  <section
    id="home"
    className="home-wrapper home2 section-b-space overflow-hidden"
    style={{
      backgroundImage:
        "url(https://capricorn-theme.com/tm/exelsior/assets/img/slider/slide-1.jpg)",
      padding: "50px 0px !important"
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-6 position-relative"></div>
        <div className="col-lg-6 col-12">
          <div
            className=""
            style={{ backgroundColor: "#fff", borderRadius: 15 }}
          >
            <form className="auth-form search-head p-3">
              <div className="row pt-2 pb-2">
                <div className="form-group mb-4 col-lg-12 text-center">
                  <h3>Apply to become a courier</h3>
                </div>
                <div className="form-group mb-4 col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name "
                  />
                </div>
                <div className="form-group mb-4 col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last name "
                  />
                </div>
                <div className="form-group mb-4 col-lg-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email "
                  />
                </div>
                <div className="form-group mb-4 col-lg-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number "
                  />
                </div>
                {/* <div className="form-group mb-4 col-lg-12">
                  <label>
                    <input type="radio" name="radio" /> SMS
                  </label>{" "}
                  <br />
                  <label>
                    <input type="radio" name="radio" /> WhatsApp
                  </label>
                </div> */}
                <div className="form-group mb-4 col-lg-6">
                  <select className="form-control">
                    <option>Select Country</option>
                  </select>
                </div>
                <div className="form-group mb-4 col-lg-6">
                  <select className="form-control">
                    <option>Select City</option>
                  </select>
                </div>
                <div className="form-group mb-4 col-lg-12">
                  <select className="form-control" disabled="">
                    <option>Select a Transport Type</option>
                  </select>
                </div>
                <div className="form-group mb-4 col-lg-12">
                <label style={{marginRight:"5px"}}>
                <input type="checkbox" />                  </label>

                {showMore ? fullText : shortText}
                  <span
                    style={{ color: '#009FE3', cursor: 'pointer' }}
                    onClick={toggleShowMore}
                  >
                    {showMore ? 'Show Less' : ' Show More'}
                  </span>
                </div>
                <div className="form-group col-lg-12 text-center">
                  <input
                    type="submit"
                    className="btn theme-btn w-100"
                    defaultValue="Apply"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* home section end */}
  {/* Featured Restaurants section starts */}
  <section className="restaurant-list pt-120 section-b-space ratio3_2">
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <img
            src="https://capricorn-theme.com/tm/exelsior/assets/img/service/02.jpg"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-lg-7 pl-3">
          <h1 style={{ fontSize: 20 }}>
          Earn a Great Weekly Rate Delivering
 <br /> Takeaway in High Wycombe 
          </h1>
          <p className="mt-3">
            {" "}
            Earn a great income, enjoy perks and work the hours you want 
            by becoming part of a growing team of couriers delivering 
            takeaway in High Wycombe.
          </p>

          <h1 style={{ fontSize: 20,marginTop:"10px" }}>
           Boost Your Income By Helping Customers
 <br />    Eat in High Wycombe  
          </h1>

          <p className="mt-3">
            {" "}
            High Wycombe Bites offers opportunities for 
            the delivery of takeaway in High Wycombe
            via car, motorcycle, bicycle and E-bikes. 
          </p>
          <h1 style={{ fontSize: 20,marginTop:"10px" }}>
              Enjoy Full Support From 
          <br/>Our Courier Team
 
          </h1>

          <p className="mt-3">
            {" "}
            When you’re delivering food from the best restaurants in
            High Wycombe , you’re not on your own! No matter if you need help
            with earnings, deliveries or suitable vehicles, you’ll find 
            everything in our always-available Help Centre. 
 
          </p>
          {/* <div className="mt-3">
            <p className="mb-3">
              <i className="fa fa-dot-circle-o" /> Quick and easy application
              process
            </p>
            <p className="mb-3">
              <i className="fa fa-dot-circle-o" /> Deliver when it suits you
            </p>
            <p className="mb-3">
              <i className="fa fa-dot-circle-o" /> Enjoy weekly earnings
            </p>
            <p className="mb-3">
              <i className="fa fa-dot-circle-o" /> Exclusive deals for couriers
            </p>
          </div> */}
        </div>
      </div>
    </div>
  </section>
  <section className="restaurant-list section-b-space banner-section ratio3_2">
    <div className="container">
      <div className="popular-restaurant section-md-t-space ratio3_2 mb-2">
        <div className="title title-sm mt-0">
          <h2>Ride With Us And Enjoy</h2>
          <div className="loader-line" />
        </div>
      </div>
      <div className="row h-100 justify-content-center">
        <div className="col-md-4 pt-4 px-md-2 px-lg-3">
          <div className="card h-100 card-span">
            <div className="card-body d-flex flex-column justify-content-around">
              <div className="text-center">
              <div style={{ width: "50px" }}> {/* Or use any preferred width */}
  <BsPiggyBank style={{ width: "100%", height: "100px",marginLeft:"90PX" }} />
</div>
                <h5 className="my-4">Competitive earnings</h5>
              </div>
              <p>
                Offering home delivery around the city, where your products will
                be at your doorstep within 48-72 hours.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 pt-4 px-md-2 px-lg-3">
          <div className="card h-100 card-span">
            <div className="card-body d-flex flex-column justify-content-around">
              <div className="text-center">
              <div style={{ width: "50px" }}> {/* Or use any preferred width */}
              <MdOutlineSchedule style={{ width: "100%", height: "100px",marginLeft:"90PX" }} />
</div>
                <h5 className="my-4">Flexible schedule</h5>
              </div>
              <p>
                Offering home delivery around the city, where your products will
                be at your doorstep within 48-72 hours.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 pt-4 px-md-2 px-lg-3">
          <div className="card h-100 card-span">
            <div className="card-body d-flex flex-column justify-content-around">
              <div className="text-center">
              <div style={{ width: "50px" }}> {/* Or use any preferred width */}
  <AiOutlineInsurance style={{ width: "100%", height: "100px",marginLeft:"90PX" }} />
</div>
                <h5 className="my-4">Insurance and benefits</h5>
              </div>
              <p>
                You can trust us to safely deliver your most sensitive documents
                to the specific area in a short time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* featured Restaurants section end */}
  <section className="section-b-space">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card bg-dark text-white py-4 py-sm-0">
            <img
              className="w-100"
              src="https://themewagon.github.io/courier/v1.0.0/assets/img/gallery/video.png"
              alt="video"
            />
          </div>
        </div>
      </div>
    </div>
    {/* end of .container*/}
  </section>
  {/* testimonial section starts */}
  <section className="section-b-space testimonial-section banner-section">
    <div className="container">
      <div className="faq-title">
        <h2>Our Client Feedback</h2>
      </div>
      <div className="swiper testimonial mb-xl-5 mb-sm-4 mb-3">
      <div className="swiper-wrapper" ref={galleryRef} style={{ display: "flex", overflowX: "auto",overflow:"hidden" }}>
        <div className="swiper-slide" style={{width:"40%"}}>
          <div className="testimonial-box">
            <img className="img-fluid comma-icon" src="assets/images/icons/commas.png" alt="commas" />
            <div className="testimonial-content">
              <p>
                I was so impressed with my breakfast this morning! I tried the
                Fried Green Tomato Benedict and my boyfriend got the Crab
                Cakes Benedict.
              </p>
              <div className="testi-bottom">
                <img className="img-fluid img" src="assets/images/icons/p1.png" alt="p1" />
                <h5 className="fw-semibold dark-text mt-2">Gunjan Puri</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide" style={{width:"40%"}}>
          <div className="testimonial-box">
            <img className="img-fluid comma-icon" src="assets/images/icons/commas.png" alt="commas" />
            <div className="testimonial-content">
              <p>
                We both finished our whole plates and were so impressed with
                the quality of the food and the short amount of time it took
                to receive it.
              </p>
              <div className="testi-bottom">
                <img className="img-fluid img" src="assets/images/icons/p2.png" alt="p2" />
                <h5 className="fw-semibold dark-text mt-2">Emily James</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide" style={{width:"40%"}}>
          <div className="testimonial-box">
            <img className="img-fluid comma-icon" src="assets/images/icons/commas.png" alt="commas" />
            <div className="testimonial-content">
              <p>
                This is always our breakfast stop before heading home from
                vacation. Always delicious. Always great service. Always worth
                the stop.
              </p>
              <div className="testi-bottom">
                <img className="img-fluid img" src="assets/images/icons/p3.png" alt="p3" />
                <h5 className="fw-semibold dark-text mt-2">Alexa Diaz</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide" style={{width:"40%"}}>
          <div className="testimonial-box">
            <img className="img-fluid comma-icon" src="assets/images/icons/commas.png" alt="commas" />
            <div className="testimonial-content">
              <p>
                The absolute best red sauce. Whether on Pizza or Pasta, it’s
                honestly delicious. Portions are huge and the staff is
                extremely friendly and courteous.
              </p>
              <div className="testi-bottom">
                <img className="img-fluid img" src="assets/images/icons/p4.png" alt="p4" />
                <h5 className="fw-semibold dark-text mt-2">Nicole Cooper</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide" style={{width:"40%"}}>
          <div className="testimonial-box">
            <img className="img-fluid comma-icon" src="assets/images/icons/commas.png" alt="commas" />
            <div className="testimonial-content">
              <p>
                It was a fantastic breakfast. Like a delicious homestyle rural
                savoury breakfast. I enjoyed the entire experience and strongly
                suggest this spot for a meal on the cape.
              </p>
              <div className="testi-bottom">
                <img className="img-fluid img" src="assets/images/icons/p5.png" alt="p5" />
                <h5 className="fw-semibold dark-text mt-2">Makenna Clark</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="swiper testimonial dir-rtl" ref={galleryRef1} >
        <div className="swiper-wrapper" >
          <div className="swiper-slide" style={{ width: "40%" }}>
            <div className="testimonial-box">
              <img
                className="img-fluid comma-icon"
                src="assets/images/icons/commas.png"
                alt="commas"
              />
              <div className="testimonial-content">
                <p>
                  I was so impressed with my breakfast this morning! I tried the
                  Fried Green Tomato Benedict and my boyfriend got the Crab
                  Cakes Benedict
                </p>
                <div className="testi-bottom">
                  <img
                    className="img-fluid img"
                    src="assets/images/icons/p1.png"
                    alt="p1"
                  />
                  <h5 className="fw-semibold dark-text mt-2">Gunjan Puri</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide" style={{ width: "40%" }}>
            <div className="testimonial-box">
              <img
                className="img-fluid comma-icon"
                src="assets/images/icons/commas.png"
                alt="commas"
              />
              <div className="testimonial-content">
                <p>
                  We both finished our whole plates and were so impressed with
                  the quality of the food and the short amount of time it took
                  to receive it.
                </p>
                <div className="testi-bottom">
                  <img
                    className="img-fluid img"
                    src="assets/images/icons/p2.png"
                    alt="p1"
                  />
                  <h5 className="fw-semibold dark-text mt-2">Maggie Martin</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide" style={{ width: "40%" }}>
            <div className="testimonial-box">
              <img
                className="img-fluid comma-icon"
                src="assets/images/icons/commas.png"
                alt="commas"
              />
              <div className="testimonial-content">
                <p>
                  This is always our breakfast stop before heading home from
                  vacation. Always delicious. Always great service. Always worth
                  the stop.
                </p>
                <div className="testi-bottom">
                  <img
                    className="img-fluid img"
                    src="assets/images/icons/p3.png"
                    alt="p1"
                  />
                  <h5 className="fw-semibold dark-text mt-2">Amina James</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide" style={{ width: "40%" }}>
            <div className="testimonial-box">
              <img
                className="img-fluid comma-icon"
                src="assets/images/icons/commas.png"
                alt="commas"
              />
              <div className="testimonial-content">
                <p>
                  The absolute best red sauce. Weather on Pizza or Pasta, it’s
                  honestly delicious. Portions are huge and the staff is
                  extremely friendly and courteous.
                </p>
                <div className="testi-bottom">
                  <img
                    className="img-fluid img"
                    src="assets/images/icons/p4.png"
                    alt="p1"
                  />
                  <h5 className="fw-semibold dark-text mt-2">Hailey Jackson</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide" style={{ width: "40%" }}>
            <div className="testimonial-box">
              <img
                className="img-fluid comma-icon"
                src="assets/images/icons/commas.png"
                alt="commas"
              />
              <div className="testimonial-content">
                <p>
                  It was a fantastic breakfast. Like a delicious homestyle rural
                  savoury breakfast. I enjoyed the entire experience and
                  strongly suggests this spot for a meal on the cape.
                </p>
                <div className="testi-bottom">
                  <img
                    className="img-fluid img"
                    src="assets/images/icons/p5.png"
                    alt="p1"
                  />
                  <h5 className="fw-semibold dark-text mt-2">Logan Ross</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* testimonial section end */}
  {/* faq section starts */}
  <section className="section-b-space">
    <div className="container">
      <div className="faq-title">
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className="row g-4">
        <div className="col-xl-4">
          <div className="side-img">
            <img
              className="img-fluid img"
              src="assets/images/faq.svg"
              alt="faq"
            />
          </div>
        </div>
        <div className="col-xl-8">
          <div
            className="accordion accordion-flush help-accordion"
            id="accordionFlushExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                >
                  I want to track my order
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  To track your order, you will need to have the tracking number
                  or order ID provided by the seller or shipping carrier. Once
                  you have this information, you can usually track your order
                  online by visiting the carrier's website and entering the
                  tracking number or order ID in the designated tracking field.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  I want to manage my order
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <ul>
                    <li>
                      Check your order confirmation email or account: This
                      should contain information about your order, including the
                      expected delivery date, tracking number (if applicable),
                      and contact information for the seller.
                    </li>
                    <li>
                      Contact the seller: If you have any questions about your
                      order or need to make changes, the best way to do so is to
                      contact the seller directly. You can typically find their
                      contact information on their website or in your order
                      confirmation email.
                    </li>
                    <li>
                      Check the order status: Many online retailers provide a
                      way for you to check the status of your order online. This
                      can give you information about when your order was
                      shipped, when it's expected to arrive, and any tracking
                      information.
                    </li>
                    <li>
                      Make changes to your order: Depending on the seller's
                      policies, you may be able to make changes to your order,
                      such as adding or removing items, changing the shipping
                      address, or canceling the order altogether. Contact the
                      seller to see if this is possible.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  I did not receive Instant Cashback
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  I'm sorry to hear that you did not receive an instant
                  cashback. To help you with this issue, I need more
                  information.
                  <ul>
                    <li>What type of purchase did you make?</li>
                    <li>
                      From which website or store did you make the purchase?
                    </li>
                    <li>
                      Did you receive any confirmation or receipt for your
                      purchase?
                    </li>
                    <li>
                      Did you check the terms and conditions of the cashback
                      offer before making the purchase?
                    </li>
                    <li>
                      What type of purchase did you make?Have you contacted the
                      website or store's customer support regarding the issue?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  I am unable to pay using wallet
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  I'm sorry to hear that you did not receive an instant
                  cashback. To help you with this issue, I need more
                  information.
                  <ul>
                    <li>What type of purchase did you make?</li>
                    <li>
                      From which website or store did you make the purchase?
                    </li>
                    <li>
                      Did you receive any confirmation or receipt for your
                      purchase?
                    </li>
                    <li>
                      Did you check the terms and conditions of the cashback
                      offer before making the purchase?
                    </li>
                    <li>
                      What type of purchase did you make?Have you contacted the
                      website or store's customer support regarding the issue?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  I want help with returns &amp; refunds
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  I'm sorry to hear that you did not receive an instant
                  cashback. To help you with this issue, I need more
                  information.
                  <ul>
                    <li>What type of purchase did you make?</li>
                    <li>
                      From which website or store did you make the purchase?
                    </li>
                    <li>
                      Did you receive any confirmation or receipt for your
                      purchase?
                    </li>
                    <li>
                      Did you check the terms and conditions of the cashback
                      offer before making the purchase?
                    </li>
                    <li>
                      What type of purchase did you make?Have you contacted the
                      website or store's customer support regarding the issue?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* faq section end */}
  {/* featured Restaurants section end */}
  {/* footer section starts */}
  <Footer/>
  {/* footer section end */}
  {/* mobile fix menu start */}
  <FooterMobileMenu/>
  {/* mobile fix menu end */}
  {/* location offcanvas start */}
  <div
    className="modal fade location-modal"
    id="location"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            <h5 className="fw-semibold">Select a Location</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="search-section">
            <form className="form_search" role="form">
              <input
                type="search"
                placeholder="Search Location"
                className="nav-search nav-search-field"
              />
            </form>
          </div>
          <a href="#!" className="current-location">
            <div className="current-address">
              <i className="ri-focus-3-line focus" />
              <div>
                <h5>Use current-location</h5>
                <h6>Wellington St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
            <i className="ri-arrow-right-s-line arrow" />
          </a>
          <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
            Recent Location
          </h5>
          <a href="#!" className="recent-location">
            <div className="recant-address">
              <i className="ri-map-pin-line theme-color" />
              <div>
                <h5>Bayshore</h5>
                <h6>kingston St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
          </a>
        </div>
        <div className="modal-footer">
          <a href="#" className="btn gray-btn" data-bs-dismiss="modal">
            Close
          </a>
          <a href="#" className="btn theme-btn mt-0" data-bs-dismiss="modal">
            Save
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* location offcanvas end */}
  {/* tap to top start */}
  <button className="scroll scroll-to-top">
    <i className="ri-arrow-up-s-line arrow" />
  </button>
  {/* tap to top end */}
  {/* responsive space */}
  <div className="responsive-space" />
  {/* responsive space */}
  {/* bootstrap js */}
  {/* footer accordion js */}
  {/* loader js */}
  {/* swiper js */}
  {/* script js */}
</>



    </div>
  )
}

export default RideWithUs