
import React from "react";
import Food from "./Food";

const FoodCategory = ({ item, restId }) => {
  console.log(restId,"restId")
  return (
    <section id={item?.category} style={{paddingTop:"0px"}} className="food-category-section">
      <div id={item?.category} className="product-details-box-title">
        {item?.category} ({item?.items?.length})
      </div>
      {item?.items?.map((food) => (
        <Food key={food.pkid} food={food} restId={restId} />
      ))}
    </section>
  );
};

export default FoodCategory;
