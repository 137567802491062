import React from "react";
import "./privacy.css"
import Navbar from "../components/Navbar";
import Delivery from "../components/delivery";
import Footer from "../components/Footer";
import FooterMobileMenu from "../components/FooterMobileMenu";
const PrivacyPolicy = () => {
    
  return (
    <>
       <Navbar /> 
      <section className="section-t-space mytabb overflow-hidden pt-120 "> 
         <Delivery /> 
      </section> 
      <div className="containers">
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy sets out the basis on which we will process any Personal Data that we may collect about you as a visitor to and user of our website at https://www.highwycombebites.co.uk. This policy further sets out how we protect your privacy and your rights in respect of our use of your Personal Data. If you are a user of our iOS or Android mobile application, please refer to our App Privacy Policy.
        </p>

        <h2>WHO IS THE DATA CONTROLLER?</h2>
        <p>
          A “data controller” is a person or organisation who alone or jointly determines the purposes for which, and the manner in which, any personal data is, or is likely to be, processed. In this sense, [Bites Limited], 6 Shelburne Court, High Wycombe, England, HP12 3NH (“Bites”, “we”, “us”, “our”) is the data controller. If you have any questions about data protection at Bites Limited in general, you can reach us by email using mailto:contact@highwycombebites.co.uk.
        </p>

        <h2>WHAT IS PERSONAL DATA?</h2>
        <p>
          Personal data is any information that relates to an identified or identifiable living individual. Different pieces of information, which collected together can lead to the identification of a particular person, also constitute Personal Data.
        </p>

        <h2>WHY DO WE HAVE A PRIVACY POLICY?</h2>
        <p>
          The UK’s Data Protection Act (“DPA”) and the EU's General Data Protection Regulation (“GDPR”) control how your Personal Data is used by us. We are also required to explain which Personal Data we collect from you via our website, what we use it for, when we delete it and how your data is protected.
        </p>
        <h2>WHAT ARE THE LEGAL BASES FOR PROCESSING PERSONAL DATA</h2>
        <p>
        All Personal Data that we obtain from you via our website will only be processed for the purposes described in more detail below. This is done within the framework of the DPA and the GDPR and only if at least one of the following applies: a) you have given your consent, b) the data is necessary for the fulfilment of a contract / pre-contractual measures, c) the data is necessary for the fulfilment of a legal obligation, or d) the data is necessary to protect the legitimate interests of our company, provided that your interests are not overridden.        </p>

        <h2>WHAT PERSONAL DATA DO WE COLLECT FROM YOU?</h2>
        <p>
          We may collect and process the following Personal Data about you:
        </p>

        <p><strong>a Personal Data that you give us:</strong></p>
        <p>
          This is information about you that you give to us by filling in forms on our website (or other forms that we ask you to complete), or correspond with us. It may include, for example, your name, address, email address, and telephone number; information about your business relationship with us; and information about your requirements and interests. The legal basis for processing is consent and the fulfilment of a contract.
        </p>

        <p>
          On our website, we offer users the opportunity to register an account. The data is entered into our registration form and transmitted to us and stored. The data is not passed on to third parties. The following data is collected as part of the registration process: a) Full name and display name, b) Email address, c) Password, d) the IP address of the user, and e) date and time of registration. The legal basis for processing is consent and the fulfilment of a contract.
        </p>

        <p>
          We process the Personal Data that you submit to us when you place your order and share your order details with the relevant restaurant for fulfilment. This Personal Data is necessary to fulfil your order, confirm your order, and evaluate your order, payment, and possible refund. The legal basis for this processing of Personal Data is that it is necessary for the performance of a contract.
        </p>

        <p>
          <strong>We process the following Personal Data in the ordering process:</strong> Name, Address data, Contact details, Order, Payment data, Comments, and your location (if applicable).
        </p>

        <p>
          If you make a payment, your Payment Data will be processed via our payment service provider Stripe. Payment data will solely be processed through Stripe and we have no access to any Payment Data you may submit. The legal basis for the provision of a payment system is the establishment and implementation of the contract.
        </p>

        <p>
          If you register your restaurant through our website, we will request mandatory and, where applicable, non-mandatory data in accordance with our registration form (Full Name, Email Address, Restaurant name, Company Details). The entry of your data is encrypted so that third parties cannot read your data when it is entered. The legal basis for processing is the provision or initiation of a contractual service and your consent.
        </p>

        <p>
          If you wish to use our listing services and its features, we process the Personal Data you voluntarily provide for the purpose of showcasing your restaurant and providing the order feature. Depending on how you use our listing services, you may provide images, contact information, company information, location data, etc. and upload content such as text, photographs, etc. This content will be made public and may be viewed and otherwise accessed by others. The legal basis for processing is the provision or initiation of a contractual service and your consent.
        </p>

        <p>
          When you are using the restaurant admin panel in your business, you become the data controller and we become the data processor. In this sense, we process the Personal Data that you or your clients or customers provide. This may include depending on your use of the restaurant admin panel, your customer contact details, business details, or details relating to their booking and the services offered by you (“Service Data”).
        </p>

        <p>
          We process your Service Data within the framework of DPA and GDPR, exclusively for the purpose of handling the contractual relationship between you and us and in accordance with your instructions. In this sense, we recognise that you own your Service Data and provide you with complete control of your Service Data by providing you the ability to (i) access your Service Data, (ii) share your Service Data, and (iii) request export or deletion of your Service Data.
        </p>

        <p>
          We ensure that access to your Service Data by our employees is only available on a need-to-know basis, restricted to specific individuals, and is logged and audited. We communicate our privacy and security guidelines to our employees and enforce privacy and protection safeguards strictly.
        </p>

        <p>
          Some jurisdictions may require you to disclose your use of our services as your processor in your privacy policy and/or data processing agreement as applicable. For this purpose, all Personal Data processed by us will be stored on our server, and we take appropriate legal precautions and corresponding technical and organisational measures to ensure the protection of service data and Personal Data in accordance with the DPA and GDPR.
        </p>

        <p><strong>b) Personal Data that our website and other systems collect about you:</strong></p>
        <p>
          If you visit our website, it will automatically collect some information about you and your visit, including the Internet protocol (IP) address used to connect your device to the Internet and some other information such as the pages on our site that you visit. This is used to monitor the performance of the website and improve the experience of visitors to the website.
        </p>

        <h2>COOKIES</h2>
        <p>
          We use so-called cookies on our website. Cookies are pieces of information that are transmitted from our web server or third-party web servers to your web browser and stored there for later retrieval. Cookies may be small files or other types of information storage. As set out in the UK’s Privacy and Electronic Communications Regulations (“PECR”) and the EU's Privacy and Electronic Communications Directive (“PECD”), we need to obtain consent for the use of Non-essential Cookies. For further information on the cookies we use, please refer to our Cookie Policy.
        </p>

        <p>
          Our website uses a cookie consent tool to obtain your consent to the storage of cookies and to document this consent. When you enter our website, the following Personal Data is transferred to us: a) Your consent(s) or revocation of your consent(s); b) Your IP address; c) Information about your browser; d) Information about your device; e) Time of your visit to our website.
        </p>

        <p><strong>c) Other Information</strong></p>
        <p>
          For business reasons, we analyse the data we have on web and server traffic patterns, website interactions, browsing behaviour etc. The analyses serve us alone and are not disclosed externally and processed using anonymous analyses with summarised and or anonymised values. For this purpose, we use Google Analytics. The legal basis is our legitimate interest and your consent. For further information, please refer to our Cookie Policy.
        </p>

        <p>
          We may also process aggregated data such as statistical or demographic data for any purpose including improving our website and services. Aggregated data could be derived from your Personal Data but is not considered Personal Data in law as this data will not directly or indirectly reveal your identity. However, if we combine or connect aggregated data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as Personal Data which will be used in accordance with this Privacy Policy.
        </p>

        <h2>DATA PROCESSING THROUGH THIRD-PARTY SERVICES</h2>
        <p>
          We use content or service offers of third-party providers on the basis of our legitimate interests in order to integrate their content and services ("content"). This always requires that the third-party providers of this content are aware of the IP address of the user, as without the IP address they would not be able to send the content to their browser. The IP address is therefore necessary for the display of this content.
        </p>

        <p>
          The following provides an overview of third-party providers and their content, together with links to their privacy policies, which contain further information on the processing of data and so-called opt-out measures, if any:
        </p>
        <ul>
          <li>Hosting: <a href="https://www.hostinger.com/privacy-policy" target="_blank" rel="noopener noreferrer">Hostinger</a></li>
          <li>Analytics: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Analytics by Google</a></li>
          <li>Fonts: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Fonts by Google</a>, and <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener noreferrer">Font Awesome of Fonticons Inc.</a></li>

          <li>Maps: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Maps by Google</a></li>
        </ul>


        <h2>HOW WILL WE USE YOUR PERSONAL DATA?</h2>
        <p>
          We will only process your Personal Data as necessary so that we can pursue the purposes described above and where we have a legal basis for such processing. Where our lawful basis for processing is that such processing is necessary to pursue our legitimate interests, we will only process your Personal Data where we have concluded that our processing does not prejudice you or your privacy in a way that would override our legitimate interest. In exceptional circumstances we may also be required by law to disclose or otherwise process your Personal Data.
        </p>

        <h2>CHANGE OF PURPOSE</h2>
        <p>
          We will only use your Personal Data for the purposes for which we collected it as detailed above, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your Personal Data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
        </p>

        <h2>DISCLOSURES OF YOUR PERSONAL DATA </h2>
        <p>
        Your Personal Data will be shared internally to ensure the efficient operation of our business (for instance, by sourcing our shared services in the most cost-effective way) and to provide the highest quality of client services. 
        </p>
        <p>Where required, we will (subject to applicable laws, our professional obligations and any terms of business which we may enter into with you) disclose your Personal Data to:

        </p>
     <p>●	any person or entity to whom we are required or requested to make such disclosure by any court of competent jurisdiction or by any governmental, taxation or other regulatory authority, law enforcement agency or similar body;
     </p>
     <p>
     ●	provide our website and service;
     </p>
     <p>
     ●	use marketing services and to advertise our services online;
     </p>
     <p>
     ●	our professional advisers or consultants, including lawyers, bankers, auditors, accountants and insurers providing consultancy, legal, banking, audit, accounting or insurance services to us;
      </p>
      <p>
      ●	any financial institutions providing finance to us; 
      </p>
      <p>
      ●	service providers who provide delivery services, information technology and system administration services to us; and
      </p>
      <p>
      ●	any external auditors who may carry out independent checks of your files.
      </p>
     <p>
     We require any person or entity to whom we disclose Personal Data to respect the confidentiality and security of your Personal Data and to treat it in accordance with applicable laws and regulations. We do not allow such recipients of your Personal Data to use it for their own purposes, and we only permit them to process your Personal Data for specified purposes and in accordance with our instructions. 
     </p>
     <p>
      <strong>International transfers </strong>
     </p>
     <p>
     We may transfer your Personal Data to other companies as necessary for the purposes described in this Privacy Policy. In order to provide adequate protection for your Personal Data when it is transferred, we have contractual arrangements regarding such transfers. We take all reasonable technical and organisational measures to protect the Personal Data we transfer.
     </p>
     <h2>MARKETING</h2>
     <p>
     Insofar as you have given us your consent to process your Personal Data for marketing and advertising purposes, we are entitled to contact you for these purposes via the communication channels you have given your consent to.
     </p>
     <p>
     You may give us your consent in a number of ways including by selecting a box on a form where we seek your permission, or sometimes your consent is implied from your interactions or contractual relationship. Where your consent is implied, it is on the basis that you would have a reasonable expectation of receiving marketing communication based on your interactions or contractual relationship with us.
      </p>
      <p>
      Our Marketing generally takes the form of email but may also include other less traditional or emerging channels. These forms of contact will be managed by us, or by our contracted service providers. Every directly addressed marketing sent by us or on our behalf will include a means by which you may unsubscribe or opt out.
        </p>
      <h2>
           ADVERTISING
      </h2>
   <p>
We would like to show you interesting advertising outside of our website and use various third-party tools and cookies for this purpose. These collect and process information about your activities on our website - for example, which products you are interested in or which pages you visit. By knowing what you are looking for and how you use our website, we can adapt our advertising to your needs. And thus increase the likelihood that you will also be shown suitable and interesting advertising outside our website.
</p>
<p>
We also analyse this data to evaluate the relevance of the advertisements and to optimise the advertisements for you. Through the tools, your browser regularly establishes a connection to the server of the tool provider when you visit our website. For some tools, we have no direct influence on what data is processed by the providers. The following personal data may be processed by third-party providers i) HTTP header information (e.g., IP address, web browser, website URL, date and time); ii) measuring pixel-specific data (e.g., pixel ID and cookie ID); and iii) additional information about visits to our website (e.g., orders placed, products clicked on). The legal bases for processing are our legitimate interest and your consent in case of cookies. For further information, please refer to our Cookie Policy.
</p>
<h2>HOW LONG DO WE KEEP YOUR PERSONAL DATA?</h2>
<p>
●	it is no longer necessary for us to retain your Personal Data to fulfil the purposes for which we had collected it; </p>
<p>●	we believe that your Personal Data that we hold is inaccurate; or </p>
<p>●	in certain cases where you have informed us that you no longer consent to our processing of your Personal Data.
</p>
<p>Sometimes, however there are legal or regulatory requirements which may require us to retain your Personal Data for a specified period, and in such cases we will retain your Personal Data for such specified period; and we may need to retain your Personal Data for certain longer periods in relation to legal disputes, and in such cases we will retain it for such longer periods to the extent required.
  </p>
  <h2>   
HOW WE SECURE YOUR PERSONAL DATA
  </h2>
  <p>
  We take appropriate organisational, technical, and physical measures to help safeguard against accidental or unlawful destruction, loss, alteration, and unauthorised disclosure of, or access to, the Personal Data we collect and process. However, no method of collection, storage, or transmission is 100% secure. You are solely responsible for protecting your password, limiting access to your devices, and signing out of websites after your sessions.
  </p>
  <h2>LINKED SITES</h2>
  <p>
  For your convenience, there may be hyperlinks on our website that link to other websites. We are not responsible for, and this Privacy Policy does not apply to the privacy practices of any linked websites or of any companies that we do not own or control. The website links may collect information in addition to the information we collect.
  </p>
  <p>
  We do not endorse any of these linked websites, their products, services, or any of the content on their websites. 
  We encourage you to seek and read the Privacy Policy of each linked website that you visit to understand how the 
  information that is collected about you is used and protected.
  </p>
  <h2>YOUR RIGHTS AND PRIVILEGES </h2>
  <p><strong>a)	Privacy rights</strong> </p>
  <p>You can exercise the following rights:</p> 
  <p>●	The right to access;</p>
  <p>●	The right to rectification;</p>
  <p>●	The right to erasure;</p>
  <p>●	The right to restrict processing;</p>
  <p>●	The right to object to processing;</p>
  <p>●	The right to data portability;</p>

  <p><strong>b)	Updating your information and withdrawing your consent </strong> </p>
<p>If you believe that the information we hold about you is inaccurate or request its rectification, deletion, or 
  object to legitimate interest processing, please do so by contacting us.</p>

  <p><strong>c)	Access Request </strong> </p>
  <p>In the event you want to make a Data Subject Access Request, please contact us. We will respond to requests 
    regarding access and correction as soon as reasonably possible. Should we not be able to respond to your request 
    within thirty (30) days, we will tell you why and when we will be able to respond to your request. If we are unable 
    to provide you with any Personal Data or to make a correction requested by you, we will tell you why.</p>
  
  <p><strong>d)	Complaint to a supervisory authority</strong> </p>
  <p>The supervisory authority in the UK is the Information Commissioner's Office (ICO) <a href="http://www.ico.org.uk/">(www.ico.org.uk)</a>  . We would, 
    however, appreciate the chance to deal with your concerns before you approach the ICO or any other supervisory 
    authority.</p>
  <p><strong>e)	What we do not do</strong> </p>
  <p>●	We do not request Personal Data from minors and children;</p>
  <p>●	We do not process special category data without obtaining prior specific consent; and</p>
  <p>●	We do not use Automated decision-making including profiling.</p>

  <h2>HELP AND COMPLAINTS</h2>
  <p>If you have any questions about this policy or the information we hold about you please contact us by email 
    using contact@highwycombebites.co.uk.</p>
    <h2>CHANGES</h2>
    <p>The first version of this policy was issued on Monday, 12th of August, 2024 and is the current version. 
      Any prior versions are invalid and if we make changes to this policy, we will revise the effective date.</p>
      </div>
      <Footer /> 
      <FooterMobileMenu /> 
    </>
  );
};

export default PrivacyPolicy;