import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { getcart } from "../store/feature/cartSlice";
import { addToCart,addOrder,loyalty } from "../utils/api";
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cartlogo from '../assets/bucketlogo.png'

const Cart = () => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const Address = JSON.parse(localStorage.getItem('userAddress'));
  const { cartItems,count } = useSelector((store) => store.Cart);
  const activeTab = useSelector((store)=>store.User.activeTab)

  const [cartItem, setCartItem] = useState(null);
  const [CartFoods, setCartFoods] = useState([]);
  const [quantities, setQuantities] = useState([]); // State to store quantities
  const [show, setShow] = useState(false);
  const [buttonText, setButtonText] = useState('PAY ONLINE');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartId, setCartId] = useState("");
  const [restId, setRestId] = useState("");
  const [type, setType] = useState("");
  const [extraTopupIds, setExtraTopupIds] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [foodQntity,setFoodQntity] = useState(0)
  const [couponCode, setCouponCode] = useState("");
  const [pincode,setPincode] = useState("")
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isCouponValid, setIsCouponValid] = useState(true);
  const cartCount = useSelector((store) => store.Cart.count);



  useEffect(() => {
    // Only dispatch getcart if cartCount is not null
    if (cartCount !== null) {
      dispatch(getcart());
    }
  }, [cartCount, dispatch]);


  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

 useEffect(() => {
  if (cartItems && cartItems.cartmasters && cartItems.cartmasters.length > 0) {
    const cartmaster = cartItems.cartmasters[0];
    setFoodQntity(cartmaster?.foods.length)

    const extraTopupIds = cartmaster.foods.flatMap(food => 
      food.carttopupdetails.flatMap(topup => 
        topup.cartextratopupdetails.map(detail => detail.extratopupid)
      )
    )



    setCartFoods(cartmaster.foods);
    setCartItem(cartmaster);
    setQuantities(cartmaster.foods.map(food => 1)); 

    // Set values for the additional fields
    setCartId(cartmaster.cartid);
    setRestId(cartmaster.restid);
    setType(cartmaster.type); 

    // setCatIds(cartmaster.foods.map(food => food.catid));
   
    setExtraTopupIds(extraTopupIds);
  }

  if(cartItems && cartItems.couponlist && cartItems.couponlist.length >0){
    const coupons = cartItems.couponlist[0]
    const coupon = cartItems.couponlist[0].couponid;
    setCouponId(coupon);
    setCouponCode(coupons)
    
  }
  if(cartItems && cartItems.deliveryfeesdata && cartItems.deliveryfeesdata.length >0){
    const fees = cartItems.deliveryfeesdata.map(delivery => delivery.pincode)
    setPincode(fees)

    
  }

}, [cartItems]);

const calculateCouponDiscount = () => {
  let discount = 0;

  if (selectedCoupon) {
    const couponValue = parseFloat(selectedCoupon.value) || 0;
    const minOrderValue = parseFloat(selectedCoupon.minvalue) || 0;

    if (totalFoodPrice >= minOrderValue) {
      if (selectedCoupon.type === "Percentage") {
        discount = (totalFoodPrice * couponValue) / 100;
      }
      //  else if (selectedCoupon.type === "Amount") {
      //   discount = couponValue;
      // }
    }
  }

  return discount;
};

const totalFoodPrice = CartFoods.reduce((total, food, index) => {
  const typeCost = parseFloat(food.typecost) || 0;
  const extraTopUpCost = food.carttopupdetails.reduce((acc, topup) => {
    return acc + parseFloat(topup.cartextratopupdetails.reduce((acc2, extra) => {
      return acc2 + parseFloat(extra.extratopupcost) || 0;
    }, 0)) || 0;
  }, 0);
  return total + (typeCost + extraTopUpCost) * quantities[index]; 
}, 0);

const serviceCharge = parseFloat(cartItem?.servicecharge) || 0;
const deliveryCharge = parseFloat(cartItem?.deliverycharge) || 0;
const couponDiscount = calculateCouponDiscount();
const totalAmountToPay = activeTab === "Delivery"
    ? totalFoodPrice + serviceCharge + deliveryCharge - couponDiscount
    : totalFoodPrice + serviceCharge - couponDiscount;
const minOrderValue = parseFloat(couponCode.minvalue) || 0;
const isCheckoutEnabled = totalAmountToPay >= minOrderValue;

// console.log("Total Food Price:", totalFoodPrice);
// console.log("Service Charge:", serviceCharge);
// console.log("Delivery Charge:", deliveryCharge);
// console.log("Coupon Discount:", couponDiscount);
// console.log("Total Amount to Pay:", totalAmountToPay);


// increament or decreament 


const handleProceed = async (food, quantity) => {
  if (!food || quantity === undefined || quantity === null) {
    // console.error("Invalid food or quantity", { food, quantity });
    return;
  }

  const sendData = {
    userid: storedUser?.userid,
    restId: restId,
    type: type,
    catid: "",
    foodid:"",
    typeid:"",
    extratopupid: extraTopupIds.length > 0 ? extraTopupIds : [],
    quantity: quantity.toString(),
    cartid: cartId,
    cartdetailid: food.cartdetailid,
  };
  
  // console.log(sendData, "datataaaaa");
  const data = await addToCart(sendData);
  if (data.status == "1") {
    // Update cart count in Redux
    dispatch({
      type: 'UPDATE_CART_COUNT',  
      payload: data.count          
    });
  }
  dispatch(getcart());

};

const handleQuantityChange = (index, newQuantity) => {
  const newQuantities = [...quantities];
  newQuantities[index] = newQuantity;
  setQuantities(newQuantities);

  // Ensure food is valid before proceeding
  const food = CartFoods[index];
  if (food) {
    handleProceed(food, newQuantity);
  } else {
    console.error("Food is undefined at index:", index);
  }
};

const handleIncrement = (index) => {
  if (quantities[index]) {
    handleQuantityChange(index, quantities[index] + 1);
  }
};

const handleDecrement = (index) => {
  if (quantities[index]) {
    handleQuantityChange(index, quantities[index] - 1);
  }else{
    dispatch(getcart());  
    console.log("get cart api ")
  }
};


  // end

  const handleNavigate = async () => {
    const savedTab = localStorage.getItem('user');
    if (savedTab) {
      navigate('/payment');
    } else {
      navigate('/login');
    }
  };

  // const handleCashOnDeliveryClick = () => {
  //   setButtonText('CONFIRM ORDER');
  // };

  // const handleConfirmOrderClick = () => {
  //   if (buttonText === 'CONFIRM ORDER') {
  //     Addorder()
  //     navigate('/confirmorder');
  //   }
  // };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // add order api 


  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); 
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


// this is for loyalty 
const [totalPoints, setTotalPoints] = useState(0);

const points = async () => {
  const data = {
    userid: storedUser?.userid,
  };

  try {
    const response = await loyalty(data);
    if (response.status === "1") {
      const total = response.details.reduce((sum, item) => sum + parseFloat(item.loyaltipoint), 0);
      setTotalPoints(total);
    } else {
      console.error("Error fetching loyalty points:", response.returnmsg);
    }
  } catch (error) {
    console.error("API error:", error);
  }
};

useEffect(() => {
  points();
}, []);

// end loyalty
const [instruction, setInstruction] = useState("");

const handleInstructionChange = (e) => {
  setInstruction(e.target.value);
};

const [paymentMode, setPaymentMode] = useState('Stripe'); // Default payment modeconst 
// handlePaymentModeChange = (mode) => {
//   setPaymentMode(mode);
// };



const Addorder = async () => {
  const paymentDateTime = getCurrentDateTime();

  if (!isCouponValid) {
    toast.error("Total amount is less than the minimum order value required for the selected coupon.");
    return;
  }

  const data = {
    customerid: storedUser?.userid,
    orderreferenceid: "",
    customerreqtime: "As soon as possible",
    ordertype: "delivery",
    deliverypostcode: pincode,
    restaurantid: restId,
    deliveryaddress: Address?.address,
    couponcodeid: couponId,
    instructionofcooking: instruction,
    loyaltipointsconsume: "0.0",
    totalitem: foodQntity.toString(),
    coupondiscount: couponDiscount.toString(),
    loyaltidiscount: "0.0",
    deliverycharges: cartItem?.deliverycharge,
    servicecharges: cartItem?.servicecharge,
    netpayamount: totalAmountToPay.toString(),
    paymentmode: paymentMode,
    paymentreferenceid: "",
    paymentdatetime: paymentDateTime,
    orderdate: paymentDateTime
  };

  try {
    const order = await addOrder(data);
    console.log(order, "order2");

    if (order.status === "1") {
      if (paymentMode === "Stripe") {
        window.location.href = order.paymenturl;
      } else {
        toast.success("Order placed successfully!");
        navigate('/confirmorder');
      }
    } else {
      toast.error("Failed to place the order. Please try again.");
    }
  } catch (error) {
    toast.error("An error occurred while placing the order.");
  }
};

const handlePaymentModeChange = (mode) => {
  console.log(mode,"mode")
  setPaymentMode(mode);
};
const handleConfirmOrderClick = () => {
  if (buttonText === 'CONFIRM ORDER') {
    Addorder()
    navigate('/confirmorder');
  }
};

const handleCashOnDeliveryClick = () => {
  handlePaymentModeChange('Cash On Delivery');
  setButtonText('CONFIRM ORDER');
  if (buttonText === 'CONFIRM ORDER') {
    Addorder()
    navigate('/confirmorder');
  }
    // Addorder()
};

const handlePayOnlineClick = () => {
  handlePaymentModeChange('Stripe');
  Addorder();
};


  const handleCouponChange = (e) => {
    const couponId = e.target.value;
    const selectedCoupon = cartItems.couponlist.find(coupon => coupon.couponid === couponId);
    setSelectedCoupon(selectedCoupon);
  };

  
  return (
    <>
      {/* <Navbar />
      <section className="section-t-space mytabb overflow-hidden pt-120">
        <Delivery />
      </section> */}
      {/* <section className="page-head-section">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">Cart</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="ri-home-line" />
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Cart
              </li>
            </ol>
          </nav>
        </div>
      </section> */}
  <div style={{ width: '100%',
    position: "sticky",
    top: "70px",
    paddingTop: "11px"}}>
  <div style={{ width: '100%' }}>
    <div className="row g-lg-4 g-4">
      <div className="col-lg-12">
        <div style={{ width: '100%'}}>
          <div style={{ width: '100%' }}>
            {CartFoods.length === 0 ? (
              <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
              <img src={Cartlogo} alt="Cart Logo" style={{ maxWidth: '50px', marginBottom: '20px', borderRadius: '50%' }} />
              <h4 style={{ color: '#333', fontSize: '18px', fontWeight: '600' }}>Fill your basket</h4>
              <p style={{ color: '#666', fontSize: '12px' }}>Your basket is empty</p>
            </div>
            
            ) : (
                <ul style={{ listStyle: 'none', padding:5, margin: 0 }}>
                {CartFoods.map((food, index) => {
                  const typeCost = parseFloat(food.typecost) || 0;
                  const extraTopUpCost = food.carttopupdetails.reduce((total, topup) => {
                    return total + parseFloat(topup.cartextratopupdetails.reduce((acc, extra) => {
                      return acc + parseFloat(extra.extratopupcost) || 0;
                    }, 0)) || 0;
                  }, 0);
                  const totalPrice = (typeCost + extraTopUpCost) * quantities[index];
              
                  return (
                    <li key={index} style={{ borderBottom: '1px solid #ddd', padding: '15px', marginBottom: '10px', borderRadius: '8px', backgroundColor: '#f9f9f9',width:"100%" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                          <h5 style={{ margin: 0, color: '#333' }}>{food.food}</h5>
                          <h6 style={{ margin: '5px 0', color: '#666' }}>{food.menutype}</h6>
                        </div>
                        <h6 style={{ margin: 0, color: '#333', fontWeight: 'bold' }}>£{totalPrice.toFixed(2)}</h6>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {/* <h6 style={{ margin: 0, color: '#777' }}>Serve 1</h6> */}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <i
                            className="ri-subtract-line sub"
                            onClick={() => handleDecrement(index)}
                            style={{ cursor: 'pointer', color: '#007bff' }}
                          />
                          <input
                            type="number"
                            value={quantities[index]}
                            readOnly
                            style={{ width: '60px', textAlign: 'center', border: '1px solid #ddd', borderRadius: '4px' }}
                          />
                          <i
                            className="ri-add-line add"
                            onClick={() => handleIncrement(index)}
                            style={{ cursor: 'pointer', color: '#007bff' }}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  
                  );
                })}
 
              </ul>
              
            )}

            {CartFoods.length > 0 && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  <h6 style={{ margin: 0, fontWeight: 'bold', color: '#333' }}>To Pay</h6>
                  <h6 style={{ margin: 0, fontWeight: 'bold' }}>£{totalAmountToPay.toFixed(2)}</h6>
                </div>

                <div style={{ marginTop: '20px' }}>
                    <Link to={"/cart"}>
                  <Button
                    className='btn theme-btn restaurant-btn w-100 rounded-2'
                  >
                    CHECKOUT
                  </Button>
                  </Link>
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Select Payment Method</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <Button
                          onClick={handleCashOnDeliveryClick}
                          className={`me-2 btn theme-btn ${buttonText === 'CONFIRM ORDER' ? 'active' : ''}`}
                        >
                          {buttonText === 'CONFIRM ORDER' ? 'CONFIRM ORDER' : 'CASH ON DELIVERY'}
                        </Button>

                        {buttonText !== 'CONFIRM ORDER' && (
                          <Button
                            className="btn theme-btn"
                            onClick={handlePayOnlineClick}
                          >
                            PAY ONLINE
                          </Button>
                        )}
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      {/* <Footer />
      <FooterMobileMenu /> */}
    </>
  );



};


export default Cart;


