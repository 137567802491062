import React, { useEffect, useRef, useState } from 'react';

const CategoriesAccordion = ({ filteredFoods, activeCategory, onCategoryClick }) => {
  const categoryListRef = useRef(null);
  const [isManualScroll, setIsManualScroll] = useState(false);

  // Fallback for onCategoryClick if not provided
  const handleCategoryClick = (category) => {
    setIsManualScroll(true);
    if (onCategoryClick) {
      onCategoryClick(category);
    } else {
      console.warn("onCategoryClick is not provided, active category won't be updated.");
    }
  };

  useEffect(() => {
    if (!isManualScroll && categoryListRef.current) {
      const activeIndex = filteredFoods.findIndex(
        (category) => category.category === activeCategory
      );

      if (activeIndex !== -1) {
        const activeItem = categoryListRef.current.children[activeIndex];
        const activeItemLeft = activeItem.offsetLeft;
        const containerWidth = categoryListRef.current.clientWidth;
        const itemWidth = activeItem.clientWidth;

        categoryListRef.current.scrollTo({
          left: activeItemLeft - containerWidth / 2 + itemWidth / 2,
          behavior: 'smooth',
        });
      }
    }
  }, [activeCategory, filteredFoods, isManualScroll]);

  useEffect(() => {
    if (isManualScroll) {
      const timer = setTimeout(() => setIsManualScroll(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [isManualScroll]);

  return (
    <div className="accordion sidebar-accordion unique" id="accordionPanelsStayOpenExample">
      <div className="accordion-item">
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionPanelsStayOpenExample"
        >
          <div
            className="accordion-body"
            style={{
              backgroundColor: '#f8f9fa',
              padding: '1rem',
            }}
          >
            <ul
              className="category-list custom-padding custom-height scroll-bar"
              id="myDIV"
              style={{
                display: 'flex',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                padding: '0',
                margin: '0',
                listStyle: 'none',
                width: '100%',
              }}
              ref={categoryListRef}
            >
              {filteredFoods.map((category, i) => (
                <li
                  key={i}
                  style={{
                    marginRight: '1rem',
                    flex: '0 0 auto',
                    padding: "10px",
                    borderRadius: "30px",
                  }}
                >

                  <a
                    href={`#${category.category}`}
                    onClick={() => handleCategoryClick(category.category)}
                    className={`${activeCategory === category.category ? 'btn theme-btn' : 'btn btn-outline '}`}
                    style={{
                
                      backgroundColor: activeCategory === category.category ? '' : '#dff5ff', // conditional background color
                    }}
                  >
                    <div className="form-check ps-0 m-0 category-list-box">
                      <div className="form-check-label">
                        <span className="name">{category.category}</span>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
};



export default CategoriesAccordion;
